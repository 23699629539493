import {api, handleResponse, handleError} from './apiUtils';
import {GET_TWO_FACTOR_AUTH, POST_TWO_FACTOR_AUTH} from '../global/Constants/twoFactorAuth'

export function GetTwoFactorAuth(phonenumber){
      return api.get(`${GET_TWO_FACTOR_AUTH}?phoneNumber=${phonenumber}`)
      .then(handleResponse)
      .catch(handleError);
}

export function PostApproveClient(phonenumber, code){
      const params = {
            PhoneNumber: phonenumber,
            code
      }
      return api.post(POST_TWO_FACTOR_AUTH, params)
      .then(handleResponse)
      .catch(handleError);
}