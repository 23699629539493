import * as types from '../actions/actionTypes'

export default function loadReducer(state = false, action) {
  switch (action.type) {
    case types.LOAD_TRUE:
      return action.isLoad
    default:
      return state
  }
}
