import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Logotype from "../../../components/kit/Logo/Logotype";
import "./ClientCab.scss";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { AUTHORIZATION } from "../../../global/Constants/Constants";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  GET_PATIEN,
  GET_PATIENT_EMC_LIST_BY_TOKEN,
  GET_PATIENT_EMC_PDF,
} from "../../../global/Constants/twoFactorAuth";
import LoadingComponent from "../OnlineRecord/LoadingComponent/LoadingComponent";
import moment from "moment";
import { GetPatientEmcPdf } from "../../../api/PatientEmcPdf";

const ClientCab = () => {
  const [cookies, setCookies, removeCookie] = useCookies(["user"]);
  const history = useHistory();
  const [patient, setPatient] = useState(null);
  const [results, setResults] = useState(null);
  const dispatch = useDispatch();

  const onLogOut = () => {
    removeCookie("user", { path: "/" });
  };

  useEffect(() => {
    if (!cookies.user) {
      history.push(AUTHORIZATION);
    }
  }, [cookies.user]);

  useEffect(() => {
    if (cookies.user) {
      const params = {
        authToken: cookies.user.token,
        // authToken: cookies.user.token
      };
      axios({
        method: "GET",
        headers: {
          Session_Id: localStorage.getItem("Session_Id"),
        },
        url: `${GET_PATIEN}`,
        params,
      })
        .then((res) => {
          if (res.data.IsSuccess) {
            setPatient(res.data.Data);
          } else {
            setPatient(null);
            history.push(AUTHORIZATION);
            removeCookie("user", { path: "/" });
          }
        })
        .catch((e) => {
          setPatient(null);
          history.push(AUTHORIZATION);
          removeCookie("user", { path: "/" });
        });

      axios({
        method: "GET",
        headers: {
          Session_Id: localStorage.getItem("Session_Id"),
        },
        url: `${GET_PATIENT_EMC_LIST_BY_TOKEN}`,
        params,
      })
        .then((res) => {
          if (res.data.IsSuccess) {
            setResults(res.data.Data);
          } else {
            setResults(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const convertDate = (selectedDate) => {
    let date = moment(selectedDate);
    date.locale("ru");
    return date.format("D MMMM YYYY") + " г.";
  };

  const DownloadPatientEmcPdf = (params) => {
    dispatch(GetPatientEmcPdf(params));
  };

  return (
    <>
      <Helmet>
        <title>Личный кабинет</title>
      </Helmet>
      <Logotype />
      {patient ? (
        <div className="ClientCab">
          <div className="date-picker-root" style={{borderRadius: '10px'}}>
          <div className="ClientCab-about">
            <div className="title">
              {patient.FirstName} {patient.LastName}
              <span class="show-more" onClick={onLogOut}>
                Выход
              </span>
            </div>
            <div className="ClientCab-info-blocks">
              {patient.DateOfBirth && (
                <div className="ClientCab-info-block">
                  <span>Дата рождения</span>
                  <p>{convertDate(patient.DateOfBirth)}</p>
                </div>
              )}
              {patient.PhoneNumber && (
                <div className="ClientCab-info-block">
                  <span>Номер телефона</span>
                  <p>+996{patient.PhoneNumber}</p>
                </div>
              )}
              {patient.Email && (
                <div className="ClientCab-info-block">
                  <span>Номер телефона</span>
                  <p>+996{patient.Email}</p>
                </div>
              )}
            </div>
          </div>

          </div>
          <div className="analyzes-table-wrapp" style={{overflowX: 'auto'}}>
            <div className="analyzes-area-table" style={{minWidth: '600px'}}>
              <div class="analyzes-area-info">
                <div class="area-info" style={{ flex:' 0 0 35%', maxWidth: '35%'}}>Наименование</div>
                <div class="area-info" style={{ flex:' 0 0 20%', maxWidth: '20%'}}>Дата приёма</div>
                <div class="area-info" style={{ flex:' 0 0 35%', maxWidth: '35%'}}>Врач</div>
                <div class="area-info" style={{ flex:' 0 0 10%', maxWidth: '10%'}}></div>
              </div>
              <div class="result-blancks">
                {results
                  ? results.map((result, i) => (
                      <div key={i} class="analyzes-area-result">
                        <div class="area-result" style={{ flex:' 0 0 35%', maxWidth: '35%'}}>{result.ServiceName}</div>
                        <div class="area-result" style={{ flex:' 0 0 20%', maxWidth: '20%'}}>
                          {moment(result.DateCreated).format("DD.MM.YYYY")}
                        </div>
                        <div class="area-result" style={{ flex:' 0 0 35%', maxWidth: '35%'}}>
                          {result.DoctorFullName.replace(
                            /\([^)]*\)/g,
                            ""
                          ).trim()}
                        </div>
                        <div
                          class="area-result"
                          onClick={() => DownloadPatientEmcPdf(result)}
                          style={{ flex:' 0 0 10%', maxWidth: '10%', color: '#d81a24', cursor: 'pointer'}}
                        >
                          Открыть
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

export default ClientCab;
