import React, { useRef, useState } from 'react'
import parse from 'html-react-parser';
import Chevron from "./Chevron";
import { currency_symbol } from './../../../constants.json'

const AcordionItem = ({speciality}) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <div className="accordion__section">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{parse(speciality.Name)}</p>
        <Chevron className={`${setRotate}`} width={10} fill={"#777"} />
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {speciality.items.map(item => (
          <div key={item.Id} className="select-service-root__option" style={{cursor: 'auto'}}>
            <div className="select-service-root__divide" style={{borderBottom: 'none'}}>
              <div className="d-flex flex-column justify-content-center">
                <p className="select-service-root__name" style={{fontWeight: '4 00'}}>{parse(item.Name)}</p>

              </div>
              <p className="select-service-root__amount">
                  {item.Price === 0
                    ? 'Бесплатно'
                    : `${item.Price} ${currency_symbol}`}
                </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AcordionItem