import React from 'react'
import { logoUrl, logoBG } from '../../../constants.json'
import {logoWidth} from '../../../constants.json'
const Logotype = ({ height, className }) => {
  return (
    <a href="https://vedanta.kg/" className={"get-result__logo"}>
      <img
        src={logoUrl}
        alt="logotype"
        width={logoWidth}
        height={height}
        className={className}
        style={{
          background: logoBG ? logoBG : "transparent",
          borderRadius: logoBG ? "15px" : '0'
        }}
      />
    </a>
  )
}

export default Logotype
