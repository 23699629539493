import React from 'react'
import { Helmet } from 'react-helmet'
import "./NotFound.scss";

const NotFound = () => {
  return (
    <>
    <Helmet>
      <title>404</title>
    </Helmet>
    <div className='NotFound'>
      <img src="https://invivo.kz/static/error-page/images/404.svg" alt="NotFound" />
      <p>Извините, на странице возникла ошибка или такой страницы не существует</p>
      <a href="https://vedanta.kg/" style={{display: 'flex', justifyContent: 'center', marginBottom: '20px', fontSize: '12px'}}>
        <span className='show-more'>На главную</span>
      </a>
    </div>
    </>
  )
}

export default NotFound