import React from "react";
import { Helmet } from "react-helmet";
import Logotype from "../../../components/kit/Logo/Logotype";
import Acordion from "../../../components/kit/Acordion/Acordion";
import { Pagination } from "@material-ui/lab";

const ServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>Список услуг</title>
      </Helmet>
      <div>
        <Logotype/>
      <div className="get-result__title title">Список услуг</div>
        <Acordion />
      </div>
    </>
  );
};

export default ServicesPage;
