import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Logotype from "../../../components/kit/Logo/Logotype";
import InputForm from "../../../components/kit/InputForm/InputForm";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";
import { useDispatch, useSelector } from "react-redux";
import InputPhoneMask from "../../../components/kit/InputPhoneMask/InputPhoneMask";
import { setIsLoad } from "../../../redux/actions/isLoad";
import { loadGetTwoFactorAuth, loadPostApproveClient } from "../../../redux/actions/twoFactorAuthActions";
import './Authorization.scss'
import reshot from '../../../assets/reshot.svg'

import { useCookies } from "react-cookie";
import { CLIENT_CAB } from "../../../global/Constants/Constants";

const Authorization = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCode, setIsCode] = useState(false)
  const [code, setCode] = useState("");
  const isLoad = useSelector((s) => s.isLoad);

  const [cookies, setCookie] = useCookies(['user']);
  const history = useHistory();

  useEffect(() => {
    if (cookies.user) {
      history.push(CLIENT_CAB);
    }
  }, [cookies.user])

  const dispatch = useDispatch()

  const disabled = !phoneNumber || isLoad;
  const disabledСode = !code || isLoad;

  const getCode = () => {
    const phone = phoneNumber.replace(/\D/g, '');
    
    dispatch(loadGetTwoFactorAuth(phone, setIsLoad, setIsCode));
  }
  const onEnterPress = (e) => {
    if (e.key === 'Enter') {

      if (!isCode) {
        getCode()

      } else {
        onAuthorization()
      }

    }
  }

  const onAuthorization = () => {
    const phone = phoneNumber.replace(/\D/g, '')
    dispatch(loadPostApproveClient(phone, setIsLoad, setIsCode, code, setCookie));
  }

  return (
    <>
      <Helmet>
        <title>Авторизация</title>
      </Helmet>
      <div>
        <Logotype/>
      <div className="title">Авторизация</div>

      {!isCode ? (
        null
      ) : (
        <span style={{color: '#ed1a34'}}>На Ваш номер телефона был отправлен код подтверждения</span>
      )}

      <div className="get-result__form" style={{marginTop: '30px'}}>
      {!isCode ? (
        <InputPhoneMask
          onKeyDown={onEnterPress}
          className="client-data-root__field "
          value={phoneNumber}
          propertyName="phone"
          onChange={(e) => {setPhoneNumber(e.target.value)}}
        />
      ) : (
        <div style={{position: 'relative'}}>
          <InputForm
            onKeyDown={onEnterPress}
            className="get-result__field"
            type="text"
            onChange={(e) => setCode(e.target.value)}
            value={code}
            propertyName="code"
            label="Код подтверждения"
          />
          <div className="reshot" onClick={getCode}>
            <img src={reshot} alt="" />
          </div>
        </div>
      )}
      </div>
        <div className="d-flex mt-30 get-result__btns">
        {!isCode ? (
          <>
            <GeneralButton
              className="mr-10"
              title="На главную"
              onClick={() => {window.location.href="https://vedanta.kg/"}}
            />
            <GeneralButton
              type="submit"
              title="Отправить код"
              onClick={getCode}
              disabled={disabled}
            />
          </>
        ) : (
          <>
            <GeneralButton
              className="mr-10"
              title="Назад"
              onClick={() =>{
                setIsCode(false)
                setCode('')
              }}
            />
            <GeneralButton
              type="submit"
              title="Войти"
              onClick={onAuthorization}
              disabled={disabledСode}
            />
          </>
        )}
        </div>
      </div>
    </>
  );
};

export default Authorization;
