import React, { useState } from "react";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";
import InputForm from "../../../../components/kit/InputForm/InputForm";
import { checkEmptyField } from "../../../../global/Helpers/checkEmptyField";
import Logotype from "../../../../components/kit/Logo/Logotype";
import "../index.scss";
import { GetResultsByCodeWordAsPdf } from "../../../../api/results";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const GetResultForm = ({ handleClickNext, inputChangeHandler }) => {
  const [codeword, setCodeword] = useState("");
  const isLoad = useSelector((s) => s.isLoad);

  const dispatch = useDispatch()

  const disabled = !checkEmptyField(codeword) || isLoad;

  const download = () => {
    dispatch(GetResultsByCodeWordAsPdf(codeword))
  }

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
        download()
    }
  }

  return (
    <>
    <Helmet>
      <title>Получение результатов</title>
    </Helmet>
    <div className="get-result">
      <Logotype/>
      <div className="get-result__title title">Результаты</div>
      <span style={{color: '#ed1a34'}}>*Кодовое слово должно состоит из букв и цифр латинского алфавита.  Кодовое слово расположено на бланке заказа. Например: GR759YR.</span>
      <div className="get-result__form" style={{marginTop: '30px'}}>
        <InputForm
          className="get-result__field"
          type="text"
          onChange={(e) => setCodeword(e.target.value)}
          value={codeword}
          propertyName="codeword"
          onKeyDown={onEnterPress}
          label="Кодовое слово"
        />
      </div>
      <div className="d-flex mt-30 get-result__btns">
        <GeneralButton
          className="mr-10"
          title="На главную"
          onClick={() => {window.location.href="https://vedanta.kg/"}}
        />
        <GeneralButton
          title="Получить результаты"
          onClick={download}
          disabled={disabled}
        />
      </div>
    </div>
    </>
  );
};

export default GetResultForm;
