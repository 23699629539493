import React from 'react';
import { useSelector } from 'react-redux';

const SelectedDoctor = ({ master, doctorSpecialities, masterPhoto, addSpecialities,  }) => {
  const selectedSpeciality = useSelector((s) => s.doctorTabsReducer.doctorTab)
  return (
    <div>
      <div className="d-flex choose-master-root__master justify-content-start align-items-center margin">
          {masterPhoto ? (
            <div className="choose-master-root__avatar" style={{background: '#fff'}}>
              <img
                src={`data:image/png;base64,${masterPhoto}`}
                alt=""
                style={{
                  width: '78px',
                  height: '78px',
                  borderRadius: '15px',
                  border: 'none',
                  objectFit: 'cover',
                }}
              />
            </div>
          ) : (
            <div className="choose-master-root__avatar">
              {master.charAt().toUpperCase()}
            </div>
          )}
        <div className="d-flex flex-column">
          <div className="choose-master-root__master-name">
            {master.toLowerCase()}
          </div>
          <p className="choose-master-root__speciality">
            {selectedSpeciality === 'все' ? addSpecialities.filter((obj) => obj.IsVisibleOnSIte).map(AddSpeciality => AddSpeciality.Name).join(', ') : selectedSpeciality}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SelectedDoctor;