import { GET_RESULTS_By_CodeWord_AsPdf } from "../global/Constants/results";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { setIsLoad } from "../redux/actions/isLoad";

const MySwal = withReactContent(Swal);

export const GetResultsByCodeWordAsPdf = (codeword) => async dispatch => {
  dispatch(setIsLoad(true))
  axios({
    method: "GET",
    responseType: "blob",
    headers: {
      Session_Id: localStorage.getItem("Session_Id"),
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    url: `${GET_RESULTS_By_CodeWord_AsPdf}?codeword=${codeword}`,
  })
    .then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      // Check if it's Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        // For Safari, try to open the PDF in a new tab
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      } else {
        // For other browsers, open the PDF in the current window
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
    .catch((error) => {
      MySwal.fire({
        title: 'Результат не найден',
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    })
    .finally(() => {
      dispatch(setIsLoad(false))
    })
  
}

// export function GetResultsByCodeWordAsPdf(codeword, setIsLoading) {
// }
