import React, { useEffect, useState } from "react";
import "./Acordion.scss";
import AcordionItem from "./AcordionItem";
import { useDispatch, useSelector } from "react-redux";
import { loadSpecialities } from "../../../redux/actions/specialityActions";
import { loadServices } from "../../../redux/actions/serviceActions";
import LoadingComponent from "../../../containers/Pages/OnlineRecord/LoadingComponent/LoadingComponent";
import usePagination from "../Pagination/Pagination";
import { Pagination } from "@material-ui/lab";
import SearchIcon from "../Icons/SearchIcon";

const Acordion = () => {
  const dispatch = useDispatch();
  const specialities = useSelector((s) => s.specialities);
  const services = useSelector((s) => s.services);
  const [search, setSearch] = useState("");

  const [sortedSpecialities, setSortedSpecialities] = useState([]);
  const [searchedSpecialities, setSearchedSpecialities] = useState([]);


  useEffect(() => {
    dispatch(loadSpecialities());
    dispatch(loadServices());
  }, []);

  useEffect(() => {
    if (services.length && specialities.length) {
      const specialitiesVisible = specialities.filter(
        (speciality) => speciality.IsVisibleOnSIte
      );
      const newSpecialities = specialitiesVisible;
      console.log(newSpecialities)
      newSpecialities.forEach((newSpeciality) => {
        newSpeciality.items = services.filter(
          (service) => service.SpecialityId === newSpeciality.Id
        );
      });
      setSortedSpecialities(newSpecialities
        .filter(newSpeciality => newSpeciality.items.length)
        .map(newSpeciality => ({...newSpeciality, Name: newSpeciality.Name.replace(/^.?\.\s/, '')}))
        .sort((a, b) => a.Name.localeCompare(b.Name))
      );
    }
  }, [services, specialities, setSortedSpecialities]);

  const onSearch = (e) => {
    const searchTerm = e.target.value;

    setSearch(searchTerm);

    const filterItems = sortedSpecialities.map(obj => {
      const filteredItems = obj.items.filter(item => {
        return (
          item.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.SpecialityName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }).map(item => {
        const formattedName = item.Name.replace(new RegExp(searchTerm, 'gi'), match => `<span>${match}</span>`);
        return {
          ...item,
          Name: formattedName,
        };
      });
    
      const formattedName = obj.Name.replace(new RegExp(searchTerm, 'gi'), match => `<span>${match}</span>`);
    
      return {
        ...obj,
        Name: formattedName,
        items: filteredItems,
      };
    }).filter(obj => obj.items.length > 0);;
    setSearchedSpecialities(filterItems)
  };

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(search ? searchedSpecialities.length / PER_PAGE : sortedSpecialities.length / PER_PAGE);
  const _DATA = usePagination(search ? searchedSpecialities : sortedSpecialities, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className="Acordion">
      {/* <input type="text" value={search} onChange={onSearch} /> */}
      <label className="choose-master-root__search" style={{marginBottom: '20px'}}>
            <SearchIcon
              width="15"
              height="15"
              className="choose-master-root__search-icon"
            />
            <input
              onChange={onSearch}
              value={search}
              type="text"
              placeholder="Поиск"
            />
          </label>
      {sortedSpecialities.length ? (        
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '15px', alignItems: 'center'}}>
          {_DATA.currentData().map(speciality => {
            return  <AcordionItem
                key={speciality.Id}
                speciality={speciality}
              />
          })}
          <div style={{marginTop: '20px'}}>
          {_DATA.currentData().length ?
            <Pagination
              count={count}
              // siblingCount={0}
              // boundaryCount={1}
              size={document.body.clientWidth >= 600 ? 'medium' : 'small'}
              page={page}
              onChange={handleChange}
              color="secondary"
            /> : null
          }
          </div>
        </div>

      ) : (
        <LoadingComponent errorMessage />
      )}
    </div>
  );
};

export default Acordion;
