import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setDoctorTabs } from '../redux/actions/doctorTabsAction'
import _ from 'lodash'
import { useHorizontalScroll } from '../global/hooks/useHorizontalScroll'
import './Doctortab.scss'

const quantity = window.innerWidth > 576 ? 8 : 9

const DoctorTab = ({ doctors, specialities }) => {
  const dispatch = useDispatch()
	const [itemsToShow, setItemsToShow] = useState(quantity);

  setTimeout(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlSpecialityId = parseInt(
      urlParams.get('specialityId') &&
        urlParams.get('specialityId').replace(/"/g, '')
    )
    const urlSpeciality = specialities.find((speciality) => {
      return speciality.Id === urlSpecialityId
    })
    urlSpeciality && dispatch(setDoctorTabs(urlSpeciality.Name))
  }, 400)

  const setDoctorTabHandler = (e) => {
    dispatch(setDoctorTabs({
		doctorTab: e.target.value,
		IdSpeciality: +e.currentTarget.id
	}))
  }

  const doctorTab = useSelector((s) => s.doctorTabsReducer.doctorTab).toLowerCase()
	const filtredSpecialities = doctors
		.reduce((acc, cur) => {
			return [...acc, ...cur.AddSpecialities]
		}, [])
		.map(speciality => ({SpecialityName: speciality.Name, IdSpeciality: speciality.Id, IsVisibleOnSIte: speciality.IsVisibleOnSIte}))
		.filter((obj, index, arr) => arr.findIndex(item => item.IdSpeciality === obj.IdSpeciality) === index && obj.IsVisibleOnSIte);

		const onShowItems = () => {
			if (itemsToShow === quantity) {
				setItemsToShow(filtredSpecialities.length)
			} else {
				setItemsToShow(quantity)
			}
		}

  return (
			<>
				<div
					className='choose-master-root__tabs'
					style={{ overflow: 'auto' }}
				>
						<button
							className={`${
								doctorTab === 'все' ? 'active' : ''
							} btn choose-master-root__tab`}
							onClick={setDoctorTabHandler}
							value={'все'}
						>
							Все
						</button>
					{filtredSpecialities.sort((prev, cur) =>  prev.SpecialityName.localeCompare(cur.SpecialityName)).slice(0, itemsToShow).map((doctor, idx) => {
						return (
								<button
									className={`${
										doctorTab === doctor.SpecialityName.toLowerCase()
											? 'active'
											: ''
									} btn choose-master-root__tab`}
									onClick={setDoctorTabHandler}
									value={doctor.SpecialityName}
									id={doctor.IdSpeciality}
									key={idx}
								>
									{doctor.SpecialityName}
								</button>
						)
					})}
				</div>
					<div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px', fontSize: '12px'}}>
						<span className='show-more' onClick={onShowItems}>{itemsToShow === quantity ? 'Показать ещё' : 'Скрыть'} </span>
					</div>
			</>
	)
}

export default DoctorTab
