import { api, handleResponse, handleError } from './apiUtils';
import { LOGIN_URL, LOGIN_URL_NEW } from '../global/Constants/user';

export function auth() {
  return api.get(LOGIN_URL)
    .then(handleResponse)
    .catch(handleError);
}

export function authLogin(params) {
  return api.post(LOGIN_URL_NEW, params)
    .then(handleResponse)
    .catch(handleError);
}