import PropTypes from 'prop-types'
import React from 'react'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import SelectServiceOption from './SelectServiceOption'
import './selectService.scss'
import { useSelector } from 'react-redux'

const SelectService = ({
  onSelect,
  categories,
  services,
  selectedOptions,
  addSpecialities
}) => {
  const selectedSpeciality = useSelector((s) => s.doctorTabsReducer.doctorTab)
  const selectedSpecialityId = useSelector((s) => s.doctorTabsReducer.IdSpeciality)

  if (categories.length === 0) return <LoadingComponent />
  else {
    return categories.map((category) => {
      let filteredServices = []
      filteredServices = services.sort((a, b) => {
        if (a.Name > b.Name) {
          return 1
        }
        if (a.Name < b.Name) {
          return -1
        }
        return 0
      }).filter(speciality => {
        if (!selectedSpecialityId) {
          const IsVisibleOnSIte = addSpecialities.find(addSpeciality => addSpeciality.Id === speciality.SpecialityId)?.IsVisibleOnSIte
          if (IsVisibleOnSIte) {
            return IsVisibleOnSIte
          } else {
            return false
          }
        } else {
          return speciality.SpecialityId === selectedSpecialityId
        }
      })
      let totalCount = 0
      for (let some of selectedOptions) {
        if (filteredServices.some((el) => el.Id === some.Id)) {
          totalCount++
        }
      }
      return (
        <>
          <h4 className="select-service-root__title">
            Все услуги врача <span>({totalCount})</span>
          </h4>
          {selectedSpecialityId ? (
            <div className="select-service-root">
              <h2>{selectedSpeciality}</h2>
              {filteredServices.filter(serv => serv.SpecialityId === selectedSpecialityId).map((el, idx) => (
                <SelectServiceOption
                  onSelect={onSelect}
                  item={el}
                  key={idx}
                  isChecked={selectedOptions.some((it) => it.Id === el.Id)}
                />
              ))}
            </div>
          ) : (
            addSpecialities.map(addSpecialy => 
            <div key={addSpecialy.Id} className="select-service-root">
              <h2>{addSpecialy.Name}</h2>
              {filteredServices.filter(serv => serv.SpecialityId === addSpecialy.Id).map((el, idx) => (
                <SelectServiceOption
                  onSelect={onSelect}
                  item={el}
                  key={idx}
                  isChecked={selectedOptions.some((it) => it.Id === el.Id)}
                />
              ))}
            </div>
            )
          )}

        </>
      )
    })
  }
}

SelectService.propTypes = {
  categories: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
}

export default SelectService
