import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { setIsLoad } from "../redux/actions/isLoad";
import { GET_PATIENT_EMC_PDF } from "../global/Constants/twoFactorAuth";

const MySwal = withReactContent(Swal);

export const GetPatientEmcPdf = (params) => async (dispatch) => {
  dispatch(setIsLoad(true))
  const body = { ...params };

  axios({
    method: "POST",
    // responseType: "blob",
    headers: {
      Session_Id: localStorage.getItem("Session_Id"),
      "Content-Type": "application/json",
    },
    url: `${GET_PATIENT_EMC_PDF}`,
    data: body
  })
    .then((data) => {
      if (data.data) {
        const byteCharacters = atob(data.data); // Декодирование Base64
        const byteNumbers = new Array(byteCharacters.length);
    
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
    
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isSafari) {
          // For Safari, try to open the PDF in a new tab
          const fileURL = window.URL.createObjectURL(blob);
          window.open(fileURL, '_blank');
        } else {
          // For other browsers, open the PDF in the current window
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }

      } else {
        MySwal.fire({
          title: 'Что-то пошло не так',
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }

    })
    .catch((error) => {
      MySwal.fire({
        title: 'Что-то пошло не так',
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    })
    .finally(() => {
      dispatch(setIsLoad(false))
    })

};

// export function GetResultsByCodeWordAsPdf(codeword, setIsLoading) {
// }
