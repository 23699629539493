import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import OnlineRecord from "./containers/Pages/OnlineRecord/OnlineRecord";
import SharePage from "./containers/Pages/SharePage/SharePage";
import {
  AUTHORIZATION,
  CLIENT_CAB,
  HOME,
  RESULTS,
  ROUTE_RECORD,
  SERVICES,
} from "./global/Constants/Constants";
import GetResultForm from "./containers/Pages/OnlineRecord/getResultForm/GetResultForm";
import { useDispatch, useSelector } from "react-redux";
import { AuthUser } from "./redux/actions/userActions";
import { TailSpin } from "react-loader-spinner";
import NotFound from "./containers/Pages/NotFound/NotFound";
import Authorization from "./containers/Pages/authorization/Authorization";
import ServicesPage from "./containers/Pages/servicesPage/ServicesPage";
import Home from "./containers/Pages/home/home";
import ClientCab from "./containers/Pages/client-cab/ClientCab";

const Routes = () => {
  const BASE_LOGIN = "WebApiUser";
  const BASE_PASSWORD = "1_G85JG44hjgGGJHG12";

  const dispatch = useDispatch();
  const isLoad = useSelector((s) => s.isLoad);

  useEffect(() => {
    dispatch(AuthUser({ Login: BASE_LOGIN, Password: BASE_PASSWORD }));
  }, []);
  return (
    <>
      <div className="container">
        <div className="online-record-root">
          {!window.location.href.includes("shareShow") && (
            <Switch>
              <Route exact path={HOME} component={Home} />
              <Route path={ROUTE_RECORD} component={OnlineRecord} />
              <Route path={RESULTS} component={GetResultForm} />
              <Route path={AUTHORIZATION} component={Authorization} />
              <Route path={SERVICES} component={ServicesPage} />
              <Route path={CLIENT_CAB} component={ClientCab} />
              <Route path="*" component={NotFound} />
            </Switch>
          )}
        </div>
        {isLoad ? (
          <>
            <div className="load">
              <TailSpin
                height="180"
                width="180"
                color="#ed1a34"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
            <div className="backdrop"></div>
          </>
        ) : null}
      </div>

      {window.location.href.includes("shareShow") && <SharePage />}
    </>
  );
};

export default Routes;
