import * as GetTwoFactorApi from '../../api/twoFactorAuthApi'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export function loadGetTwoFactorAuth(phone, setIsLoad, setIsCode) {
  return function (dispatch) {
    dispatch(setIsLoad(true))
    return GetTwoFactorApi
      .GetTwoFactorAuth(phone)
      .then((data) => {
        setIsCode(true)
      })
      .catch((error) => {
        MySwal.fire({
          title: 'Пользователь не найден',
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        throw error
      })
      .finally(() => {
        dispatch(setIsLoad(false))
      })
  }
}

export function loadPostApproveClient(phone, setIsLoad, setIsCode, code, setCookie) {
  return function (dispatch) {
    dispatch(setIsLoad(true))

    return GetTwoFactorApi
      .PostApproveClient(phone, code)
      .then((data) => {
        setIsCode(true)
        const cokieParams = {
          token: data,
          phone
        }

        console.log(cokieParams)

        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);

        setCookie('user', cokieParams, { path: '/', expires: expirationDate });
      })
      .catch((error) => {
        MySwal.fire({
          title: 'Код истёк или введённые данные неверны.',
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        console.log(error)
      })
      .finally(() => {
        dispatch(setIsLoad(false))
      })
  }
}
